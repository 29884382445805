import emailjs from 'emailjs-com';
import { YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, YOUR_SECOND_TEMPLATE_ID, YOUR_USER_ID } from './configs'

function sendSecondEmail(templateParams) {

  return emailjs.send(YOUR_SERVICE_ID, YOUR_SECOND_TEMPLATE_ID, templateParams, YOUR_USER_ID)
}

function sendEmail(templateParams) {

  return emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
}

export { sendEmail, sendSecondEmail }
