import React, { forwardRef } from "react";
import PropTypes from 'prop-types';
import cn from 'classnames'

import "./style.css"

const Input = forwardRef(({error, className, ...props}, ref) => (
  <input
    {...props}
    ref={ref}
    className={cn(
      "custom-input",
      { 'custom-input__error': error },
      className,
      )
    }
  />
))

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

Input.defaultProps = {
  className: '',
  error: false,
}

export default Input;
