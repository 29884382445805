import React, { useState } from "react"
import cn from "classnames"

import MobileMenu from '../mobil-menu'

import { ReactComponent as WalletIcon } from "../../assets/svg/wallet.svg";
import { ReactComponent as MobileMenuIcon } from "../../assets/svg/actions/mobile-menu.svg";
import logo from '../../assets/svg/logo.svg'

import './style.css'
import { Link } from "react-router-dom";

const advancedLinks = [
  {
    url: '',
    text: 'Home',
    disabled: false
  },
  {
    url: 'https://drive.google.com/file/d/1YOzsZPH5nCd67nqLxuBBFU9AG5kmOvxY/view?usp=sharing',
    text: 'Lightpaper',
    href: true,
    target: true,
    disabled: false
  },
  {
    url: 'https://discord.com/invite/duAySrR4c4',
    text: 'Community',
    href: true,
    target: true,
    disabled: false
  },
  {
    url: '',
    text: 'Blog',
    disabled: true
  },
  {
    url: '/contacts',
    text: 'Contact',
    disabled: false,
    local: true
  },
]

const NoAuthHeader = ({ className }) => (
  <div className={cn("header-logo", className)}>
    <img className="logo-img" src={logo} alt="logo" />
    <h1 className="logo-text">Altar</h1>
  </div>
)

const AdvancedHeader = ({ mobileView }) => {
  const [showMobile, setShowMobile] = useState(false)

  return (
    <div className="advanced-header">
      <div className="advanced-header__logo" >
        <img className="advanced-header__logo-img" src={logo} alt="logo" />
        <h1 className="advanced-header__logo-text">Altar</h1>
      </div>
      <div className="advanced-header__menu">
        { advancedLinks.map((link) =>
        {
          if (link.local) {
            return (
              <Link
                className={cn("advanced-header__menu-item", link.disabled && "advanced-header__item-disabled")}
                to={link.url}
              >
                { link.text }
              </Link>
            )
          }

          return (
            <a
              className={cn("advanced-header__menu-item", link.disabled && "advanced-header__item-disabled")}
              href={link.url || '#'}
              key={link.text}
              target={link.target && '_blank'}
            >
              { link.text }
            </a>
          )
        }
        )}
      </div>
      <div className="advanced-header__mobile-menus">
        <button className="advanced-header__mobile-wallet">
          <WalletIcon/>
          <span>Connect Wallet</span>
        </button>
        <button onClick={() => setShowMobile(true)} className="advanced-header__mobile-menu">
          <MobileMenuIcon />
        </button>
      </div>
      { mobileView &&  <MobileMenu closeMenu={() => setShowMobile(false)} open={showMobile} links={advancedLinks} />}
    </div>
  )
}

export { AdvancedHeader }

export default NoAuthHeader