import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import cn from 'classnames';

import NoAuthHeader from "../../components/header";
import Input from "../../components/input";
import Button from "../../components/button";

import { ReactComponent as CancelIcon } from "../../assets/svg/actions/cancel-circle.svg";
import './styles.css';

import { validatePhoneNumber, validateEmail } from '../../utils/validators'
import { sendEmail } from '../../utils/email'

const Whitelist = ({ contacts }) => {
  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit } = useForm();

  const formRef = useRef(null)

  const goBack = (e) => {
    e.preventDefault()
    navigate('/')
  }

  const onSubmit = (data) => {
    sendEmail(data)
    .then(() => {
      navigate('/success')
    })
    .catch((err) => {
      console.error(err)
    })
  }

  return <div className="whitelist-wrapper">
    <div className={cn("whitelist-background", contacts && 'whitelist-background-contacts')}></div>
    <NoAuthHeader className="whitelist-header" />
    <button onClick={goBack} className="whitelist__cancel">
      <CancelIcon />
    </button>
    <form
      name="whitelist"
      ref={formRef}
      className="whitelist-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="whitelist-form__title">
        { contacts ? "Contact us" : "Get whitelisted"}
      </h1>
      <Input
        className="whitelist-form__input"
        {...register("name", { required: 'The field is required' })}
        placeholder="Name"
        error={errors?.name?.message || ''}
      />
      <Input
        className="whitelist-form__input"
        {...register("email", {
          required: 'The field is required',
          pattern: {
            value: validateEmail,
            message: "Entered value does not match email format"
          }
        })}
        placeholder="Email"
        error={errors?.email?.message || ''}
      />
      <Input
        className="whitelist-form__input"
        {...register("phone", {
          required: 'The field is required',
          pattern: {
            value: validatePhoneNumber,
            message: "Phone not valid"
          }
        })}
        placeholder="Phone number"
        error={errors?.phone?.message || ''}
      />
      <div className="whitelist-form__actions">
        <Button type="button" onClick={goBack} cancel>Cancel</Button>
        <Button className="whitelist-submit" submit type="submit">{ contacts ? "SEND" : "Submit"}</Button>
      </div>
    </form>
    
  </div>
}

export default Whitelist
