import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import history from './store/history';

import ComingSoon from './containers/coming-soon'
import Whitelist from './containers/whitelist'
import Success from './containers/success'

function App() {
  return (
  <BrowserRouter history={history}>
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="/whitelist" element={<Whitelist />} />
      <Route path="/contacts" element={<Whitelist contacts />} />
      <Route path="/success" element={<Success />} />
      <Route path="*" element={<ComingSoon />} />
    </Routes>
  </BrowserRouter>
  )
}

export default App;
