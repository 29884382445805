import React from 'react';
import cn from 'classnames'
import { ReactComponent as CloseIcon } from '../../assets/svg/actions/cancel-circle.svg'
import Logo from '../../assets/svg/logo.svg'

import './styles.css';
import { Link } from 'react-router-dom';

const MobileMenu = ({ open, closeMenu, links }) => {
  return (
    <div style={{ right: open ? '0' : '-100vw' }} className="mobile-menu">
      <div className={"mobile-menu__content"} >
        <div className="mobile-menu__content-background"></div>
        <div className="mobile-menu__header">
          <div className="mobile-menu__header-logo">
            <img src={Logo} alt="Logo" />
            <h1>Altar</h1>
          </div>
          <button className="mobile-menu__header-collapse" onClick={closeMenu}>
            <CloseIcon />
          </button>
        </div>
        <nav className="mobile-menu__nav">
          { links.map((option) => {
            if (option.local) {
              return (
                <Link
                  onClick={closeMenu}
                  to={option.url }
                  className={cn(option.disabled && "mobile-menu__nav-disable")}
                >
                  {option.text}
                </Link>
              )
            }

            return (
              <a
                target={option.href & "_blank"}
                className={cn(option.disabled && "mobile-menu__nav-disable")}
                key={option.text} href={option.url || '#'}
              >
                {option.text}
              </a>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export default MobileMenu