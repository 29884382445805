import React from "react";
import { useNavigate } from "react-router-dom";

import NoAuthHeader from "../../components/header";
import Button from "../../components/button";
import { ReactComponent as OkIcon } from '../../assets/svg/actions/ok.svg'

import { ReactComponent as CancelIcon } from "../../assets/svg/actions/cancel-circle.svg";
import './styles.css';

const Whitelist = () => {
  const navigate = useNavigate();

  const goBack = (e) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <div className="success-wrapper">
      <div className="success-background"></div>
      <NoAuthHeader className="success-header" />
      <button onClick={goBack} className="success__cancel">
        <CancelIcon />
      </button>
      <div className="success-detail">
        <h1 className="success-detail__title" >Succesfuly!</h1>
        <h3  className="success-detail__bub-title" >Your request has been sent successfully</h3>
        <Button onClick={goBack} className="success-detail__btn" submit customIcon={<OkIcon />}>OK</Button>
      </div>
    </div>
  )
}

export default Whitelist
