import React from "react";
import PropTypes from 'prop-types';
import cn from 'classnames'

import { ReactComponent as CloseIcon } from '../../assets/svg/actions/close.svg'
import { ReactComponent as SubmitIcon } from '../../assets/svg/actions/submit.svg'

import "./style.css"

const Button = ({ cancel, submit , className, children, label, customIcon, ...props }) => {

  const viewIcon = () => {
    if (customIcon) return customIcon
    if (cancel) return <CloseIcon />
    if (submit) return <SubmitIcon />
    return <SubmitIcon />
  }

  return (
    <button
      {...props}
      className={cn(
        'custom-btn',
        { 'custom-btn__cancel':  cancel },
        { 'custom-btn__submit':  submit },
        className,
      )}
    >
      <span className="custom-btn__view">
        { viewIcon() }
      </span>
      <span className="custom-btn__text">{ children || label }</span>
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  cancel: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  cancel: false,
}

export default Button;
